'use client';
import { useTranslation } from '@/app/i18n/client';
import NextStepCard from '@/components/next-step-card';
import { Tours } from '@/lib/constants';
import { USER_EMAIL_COOKIE } from '@/middleware';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FlagProvider } from '@unleash/nextjs';
import { useRouter } from 'next/navigation';
import { NextStep, NextStepProvider } from 'nextstepjs';
import { PropsWithChildren, useMemo } from 'react';
import { RouterProvider } from 'react-aria-components';
import { useCookies } from 'react-cookie';
import lngContext from '../hooks/use-lng';

declare module 'react-aria-components' {
    interface RouterConfig {
        routerOptions: NonNullable<
            Parameters<ReturnType<typeof useRouter>['push']>[1]
        >;
    }
}

interface Props {
    lng: string;
}

function makeQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                refetchOnWindowFocus: false,
                retry(failureCount) {
                    return failureCount < 3;
                },
            },
        },
    });
}

export let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
    if (typeof window === 'undefined') {
        return makeQueryClient();
    } else {
        if (!browserQueryClient) browserQueryClient = makeQueryClient();
        return browserQueryClient;
    }
}

export default function Providers({ children, lng }: PropsWithChildren<Props>) {
    const queryClient = getQueryClient();
    const router = useRouter();

    const { t } = useTranslation(lng);

    const [cookies] = useCookies([USER_EMAIL_COOKIE, 'unleash-session-id']);

    const tours = useMemo(
        () => [
            {
                tour: Tours.multiLogin,
                steps: [
                    {
                        title: '🚀 ' + t('tours.multi_login.title'),
                        content: t('tours.multi_login.content_1'),
                        icon: '',
                        nextRoute: '/integrations',
                        showControls: true,
                        showSkip: true,
                    },
                    {
                        title: '🚀 ' + t('tours.multi_login.title'),
                        content: t('tours.multi_login.content_2'),
                        icon: '',
                        selector: '[data-tour="google-ads-connect"]',
                        side: 'left' as const,
                        prevRoute: '/campaigns',
                        showControls: false,
                        showSkip: true,
                    },
                    {
                        title: '🚀 ' + t('tours.multi_login.title'),
                        content: t('tours.multi_login.content_3'),
                        icon: '',
                        selector: '[data-tour="integration-save"]',
                        side: 'left' as const,
                        showSkip: true,
                    },
                    {
                        title: '🚀 ' + t('tours.multi_login.title'),
                        content: t('tours.multi_login.content_4'),
                        icon: '<no_back>',
                        showSkip: true,
                        showControls: true,
                        nextRoute: '/tools/audience-optimization',
                    },
                    {
                        title: '🚀 ' + t('tours.multi_login.title'),
                        content: t('tours.multi_login.content_5'),
                        icon: '',
                        showSkip: true,
                        prevRoute: '/integrations',
                        showControls: true,
                    },
                ],
            },
        ],
        [t],
    );

    return (
        <FlagProvider
            config={{
                context: {
                    userId: cookies.midas_user_email,
                    sessionId: cookies['unleash-session-id'],
                },
                refreshInterval: 60 * 1000,
            }}
        >
            <NextStepProvider>
                <NextStep
                    cardComponent={(props) => (
                        <NextStepCard
                            {...props}
                            i18n={{
                                back: t('back'),
                                next: t('next'),
                                skip: t('skip'),
                                finish: t('finish'),
                            }}
                        />
                    )}
                    steps={tours}
                >
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider navigate={router.push}>
                            <lngContext.Provider value={lng}>
                                {children}
                            </lngContext.Provider>
                        </RouterProvider>
                    </QueryClientProvider>
                </NextStep>
            </NextStepProvider>
        </FlagProvider>
    );
}
