import { CardComponentProps } from 'nextstepjs';
import { memo } from 'react';

interface Props extends CardComponentProps {
    i18n: {
        back: string;
        next: string;
        skip: string;
        finish: string;
    };
}

function NextStepCard({
    step,
    currentStep,
    totalSteps,
    nextStep,
    prevStep,
    skipTour,
    arrow,
    i18n,
}: Props) {
    const iconIsCommand =
        typeof step.icon === 'string' && step.icon.includes('<');
    const commands = iconIsCommand ? step.icon?.toString().split('/') : [];

    return (
        <div className="flex w-max max-w-lg flex-col gap-4 rounded-lg border border-gray-200 bg-white p-6 shadow-lg">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold">{step.title}</h2>
                {step.icon && !iconIsCommand ? step.icon : null}
            </div>

            <p>{step.content}</p>

            <div className="mb-4 h-2.5 rounded-full bg-gray-200">
                <div
                    className="h-2.5 rounded-full bg-primary"
                    style={{
                        width: `${((currentStep + 1) / totalSteps) * 100}%`,
                    }}
                ></div>
            </div>
            {step.showControls && (
                <div className="mt-4 flex justify-between gap-4">
                    {commands?.includes('<no_back>') ? (
                        <div />
                    ) : (
                        <button
                            onClick={prevStep}
                            disabled={currentStep === 0}
                            className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
                        >
                            {i18n.back}
                        </button>
                    )}

                    <button
                        onClick={nextStep}
                        className="rounded-md bg-primary px-4 py-2 text-gray-800"
                    >
                        {currentStep === totalSteps - 1
                            ? i18n.finish
                            : i18n.next}
                    </button>
                </div>
            )}
            {step.showSkip && (
                <button
                    onClick={skipTour}
                    className="rounded-md bg-slate-300 px-4 py-2 text-gray-800"
                >
                    {i18n.skip}
                </button>
            )}
            {arrow}
        </div>
    );
}

export default memo(NextStepCard);
